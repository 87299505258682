import 'core-js/stable'
import 'regenerator-runtime/runtime'

$(".best_in_place").best_in_place();

import Swal from 'sweetalert2';
window.Swal = Swal;

$(document).on('click', 'a[href*="#"]', function (event) {
    event.preventDefault();
    const hash = this.hash; // Отримання значення хешу
    const linkHref = $(this).attr('href'); // Отримання посилання

    if (hash) {
        // Спочатку відбувається анімація скролу
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 500, function() {
            // Після завершення анімації, виконуємо перехід за посиланням
            setTimeout(function() {
                window.location.href = linkHref;
            }, 1); // 2 секунди затримки перед переходом
        });
    }
});



window.onload = function() {
    // Визначаємо якір, до якого ми хочемо прокрутити сторінку
    var anchor = document.getElementById('subcategory-anchor');

    // Перевіряємо, чи існує такий якір на сторінці
    if (anchor) {
        // Вираховуємо позицію якоря відносно верхнього краю сторінки
        var offsetTop = anchor.offsetTop;

        // Прокручуємо сторінку до вирахованої позиції
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth' // Анімація прокручування
        });
    }

};

// Очікуємо завершення завантаження сторінки
window.addEventListener('load', function() {
    // Отримуємо поточний URL
    var currentUrl = window.location.href;

    // Перевіряємо, чи адреса містить '/menu#subcategory-anchor'
    if (currentUrl.includes('/menu#subcategory-anchor')) {
        // Знаходимо елемент якоря №menu-anchor
        var menuAnchorElement = document.getElementById('menu-anchor');

        // Перевіряємо, чи знайдено елемент якоря
        if (menuAnchorElement) {
            // Виконуємо плавний скролінг до елементу якоря
            menuAnchorElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
});

$(document).on('click', function () {
    const phoneNumberInput = document.getElementById("order_phone_number");

    if (!phoneNumberInput) {
        return;
    }

    phoneNumberInput.addEventListener("keydown", function (e) {
        const cursorPosition = phoneNumberInput.selectionStart;

        if (cursorPosition < 3 && (e.key === "Backspace" || e.key === "Delete")) {
            e.preventDefault();
        }

        if (!e.key.match(/[0-9]/) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"].includes(e.key)) {
            e.preventDefault();
        }
    });

    phoneNumberInput.addEventListener("input", function () {
        if (!phoneNumberInput.value.startsWith("+38")) {
            phoneNumberInput.value = "+38" + phoneNumberInput.value.replace(/[^0-9]/g, "");
        }

        const digitsOnly = phoneNumberInput.value.replace(/\D/g, "").slice(2, 12);
        phoneNumberInput.value = "+38" + digitsOnly;

        // Check if minlength is respected
        phoneNumberInput.setCustomValidity(phoneNumberInput.value.length === 13 ? "" : "Номер телефону має містити рівно 13 символів");
    });

    document.querySelector('form').addEventListener('submit', function (e) {
        if (!phoneNumberInput.checkValidity()) {
            e.preventDefault();
            phoneNumberInput.reportValidity();
        }
    });
});
